import New from '@components/partials/svg/new';
import ReadReview from '@components/partials/svg/readReview';
import Trending from '@components/partials/svg/trending';
import styles from '@styles/scss/category/card.module.scss';
import { getFlag } from 'lib/helper/flag';
import { categoryType } from 'lib/types/item/categorytype';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';

export default function CategoryCard({
    category,
    language
}: {
    category: categoryType;
    language?: string;
}): JSX.Element {
    const [showToolTip, setToolTip] = useState(0);
    const [arr, setArr] = useState(category.activeSites);
    const cookies = parseCookies();
    useEffect(() => {
        if (cookies.u) {
            const updatedSites = category.activeSites.map((site) => {
                site.siteOutgoingLink = site.siteOutgoingLink?.replace('{exid}', cookies.u);
                return site;
            });
            setArr(updatedSites);
        }
    }, [cookies.u]);

    return (
        <>
            <div className={styles.card} data-pint-1320="setToNone">
                <a className={styles.anchor} id={category.categorySlug}>
                    {' '}
                </a>
                {category.categoryReviewActive ? (
                    <Link prefetch={false} href={'/' + category.categorySlug}>
                        <a>
                            <h3>{category.categoryName}</h3>
                        </a>
                    </Link>
                ) : (
                    <h3>{category.categoryName}</h3>
                )}
                {category.categoryMiniDescription && (
                    <div className={styles.description}>{category.categoryMiniDescription}</div>
                )}
                <div className={styles.rankingList}>
                    {arr.map((site: any, index: number) => (
                        <div
                            className={`${styles.rankingItem} ${
                                site.siteTrending ? styles.trending : undefined
                            }`}
                            key={site.sitePosition + site.siteName}>
                            <div className={styles.tooltipContainer}>
                                <div className={styles.rank}>{index + 1}</div>
                                <Link
                                    prefetch={false}
                                    href={`${
                                        site.siteUseOpenSite
                                            ? '/out/' + site.siteReviewSlug
                                            : site.siteOutgoingLink
                                    }`}>
                                    <a
                                        className={styles.site}
                                        data-site-name={site.siteName}
                                        data-site-category={category.categoryName}
                                        target="_blank"
                                        rel={
                                            site.siteUseOpenSite
                                                ? 'nofollow noopener noreferrer'
                                                : 'noopener noreferrer'
                                        }>
                                        <div
                                            className={styles.icon}
                                            style={{ backgroundPosition: site.siteIconLogo }}></div>
                                        {site.siteName}
                                    </a>
                                </Link>
                                {language && site.siteHasFlag && (
                                    <div className={styles.flagIcon}>{getFlag(language)}</div>
                                )}
                                {site.siteReviewActive && (
                                    <Link
                                        prefetch={false}
                                        href={`/${
                                            site.siteCategorySlug
                                                ? site.siteCategorySlug
                                                : category.categorySlug
                                        }/${site.siteReviewSlug}`}>
                                        <a
                                            className={`${styles.tooltip} ${
                                                showToolTip === site.sitePosition
                                                    ? styles.visible
                                                    : styles.hidden
                                            }`}>
                                            {`Read ${site.siteName} Review`}
                                        </a>
                                    </Link>
                                )}
                            </div>
                            {site.siteReviewActive ? (
                                <Link
                                    prefetch={false}
                                    href={`/${
                                        site.siteCategorySlug
                                            ? site.siteCategorySlug
                                            : category.categorySlug
                                    }/${site.siteReviewSlug}`}>
                                    <a
                                        className={styles.reviewEn}
                                        onMouseOver={() => setToolTip(site.sitePosition)}
                                        onMouseOut={() => setToolTip(0)}
                                        onFocus={() => setToolTip(site.sitePosition)}
                                        onBlur={() => setToolTip(0)}>
                                        <div className={styles.iconReview}>
                                            <ReadReview />
                                        </div>
                                        {site.siteTrending && (
                                            <div className={styles.iconTrending}>
                                                <Trending />
                                            </div>
                                        )}
                                        {site.siteNew && (
                                            <div className={styles.iconNew}>
                                                <New />
                                            </div>
                                        )}
                                    </a>
                                </Link>
                            ) : (
                                <div className={styles.review}>
                                    {site.siteTrending && (
                                        <div className={styles.iconTrending}>
                                            <Trending />
                                        </div>
                                    )}
                                    {site.siteNew && (
                                        <div className={styles.iconNew}>
                                            <New />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
