import EmailCardFormSimple from '@components/partials/emailSignUp/cardFormSimple';
import styles from '@styles/scss/emailSignUp/card.module.scss';

export default function EmailCard(): JSX.Element {
    return (
        <>
            <div className={styles.card}>
                <h3>Sign Up!</h3>
                <div className={styles.description}>
                    Deals, free tokens, and minutes for the best sex cams. - Straight to your inbox!
                </div>
                <EmailCardFormSimple />
            </div>
        </>
    );
}
