import styles from '@styles/scss/common/_blogCard.module.scss';

export default function BlogCard(): JSX.Element {
    return (
        <>
            <div className={styles.card} data-pint-1320="setToNone">
                <h2>
                    <a
                        href="https://www.myfavsexcams.xxx/blog/cam-user-guide/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Become An Expert Cam User
                    </a>
                </h2>
            </div>
        </>
    );
}
