import BlogCard from '@components/partials/common/blogCard';
import EmailCard from '@components/partials/emailSignUp/card';
import styles from '@styles/scss/category/list.module.scss';
import React from 'react';
const languageFiles = (require as any).context('public/locales', false, /\.json$/);

import CategoryCard from './card';

export default function CategoryList({
    categoryList,
    language
}: {
    categoryList: any;
    language?: string;
}): JSX.Element {
    const languageFile = languageFiles(`./${language}.json`);
    const categories: any = [];
    Object.keys(categoryList)
        .map((categoryName) => categoryList[categoryName])
        .map((categoryData) => {
            if (categoryData.categoryActive) {
                categories.push(categoryData);
            }
        });
    const colOne: any = [];
    const colTwo: any = [];
    const colThree: any = [];
    const colFour: any = [];

    categories.map((category: any) => {
        switch (category.categoryColumn) {
            case 1:
                colOne.push(category);
                break;
            case 2:
                colTwo.push(category);
                break;
            case 3:
                colThree.push(category);
                break;
            case 4:
                colFour.push(category);
                break;
        }
    });

    return (
        <>
            <div className={styles.categoryBanner}>{languageFile.best_sites}</div>
            <div className={styles.categories} data-pint-1320="setToNone">
                <div className={styles.firstWrapper}>
                    <div className={styles.firstCol}>
                        {colOne.map((category: any, index: number) =>
                            index === 1 ? (
                                <React.Fragment key={category.categoryName}>
                                    <div className={styles.showTwo}>
                                        <BlogCard />
                                    </div>
                                    <div className={styles.showTwo}>
                                        <EmailCard />
                                    </div>
                                    <CategoryCard category={category} language={language} />
                                    <div className={styles.showOne}>
                                        <BlogCard />
                                    </div>
                                    <div className={styles.showOne}>
                                        <EmailCard />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <CategoryCard
                                    category={category}
                                    language={language}
                                    key={category.categoryName}
                                />
                            )
                        )}
                    </div>
                    <div className={styles.secondCol}>
                        {colTwo.map((category: any, index: number) =>
                            index === 0 ? (
                                <React.Fragment key={category.categoryName}>
                                    <div className={styles.showThree}>
                                        <BlogCard />
                                    </div>
                                    <CategoryCard category={category} language={language} />
                                    <div className={styles.showThree}>
                                        <EmailCard />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <CategoryCard
                                    category={category}
                                    language={language}
                                    key={category.categoryName}
                                />
                            )
                        )}
                    </div>
                </div>
                <div className={styles.secWrapper}>
                    <div className={styles.thirdCol}>
                        {colThree.map((category: any, index: number) => (
                            <CategoryCard
                                category={category}
                                language={language}
                                key={category.categoryName}
                            />
                        ))}
                    </div>
                    <div className={styles.fourthCol}>
                        {colFour.map((category: any, index: number) => (
                            <CategoryCard
                                category={category}
                                language={language}
                                key={category.categoryName}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
